<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__heading">
            <h1 class="t-main__title">アンケート管理</h1>
        </div>

        <div class="t-main__form">
            <forms @submit.prevent="$emit('search-table-data', $event)">
                <div class="t-main__form-input">
                    <field v-bind="forms.clientName">
                        <template v-slot:field="fieldProps">
                            <text-field
                                v-bind="fieldProps.field"
                                :aria-required="fieldProps.field.isRequire"
                                :model-value="models.draftSearch.clientName"
                                @update:model-value="$emit('update:draftClientName', $event)"
                            >
                                <template v-slot:action>
                                    <button type="button" :disabled="models.draftSearch.clientName === ''" @click="$emit('set-draft-model-value', 'clientName', '')"><icon name="Close" /></button>
                                </template>
                            </text-field>
                        </template>
                    </field>

                    <div class="t-main__form-input-wrap">
                        <field v-bind="forms.clientId">
                            <template v-slot:field="fieldProps">
                                <text-field
                                    v-bind="fieldProps.field"
                                    :aria-required="fieldProps.field.isRequire"
                                    :model-value="models.draftSearch.clientId"
                                    @update:model-value="$emit('update:draftClientId', $event)"
                                >
                                    <template v-slot:action>
                                        <button type="button" :disabled="models.draftSearch.clientId === ''" @click="$emit('set-draft-model-value', 'clientId', '')"><icon name="Close" /></button>
                                    </template>
                                </text-field>
                            </template>
                        </field>
                    </div>

                    <div class="t-main__form-input-wrap">
                        <field v-bind="forms.surveyId">
                            <template v-slot:field="fieldProps">
                                <text-field
                                    v-bind="fieldProps.field"
                                    :aria-required="fieldProps.field.isRequire"
                                    :model-value="models.draftSearch.surveyId"
                                    @update:model-value="$emit('update:draftSurveyId', $event)"
                                >
                                    <template v-slot:action>
                                        <button type="button" :disabled="models.draftSearch.surveyId === ''" @click="$emit('set-draft-model-value', 'surveyId', '')"><icon name="Close" /></button>
                                    </template>
                                </text-field>
                            </template>
                        </field>
                    </div>
                </div>

                <template v-slot:footer>
                    <form-footer>
                        <button-group>
                            <standard-button appearance="primary" type="submit" spacing="fill" :is-loading="forms.isLoading.value"><b>検索</b></standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>
        </div>

        <div class="t-main__utils">
            <tab-list v-bind="tabs" :current-index="tabs.currentIndex.value">
                <template v-slot:tab="slotProps">
                    <tab v-bind="slotProps.item" :is-external-link="false">{{slotProps.item.label}}</tab>
                </template>
            </tab-list>
        </div>

        <tables v-bind="surveyTableData" :is-loading="surveyTableData.isLoading.value" :body-rows="surveyTableData.bodyRows.value">
            <template v-slot:table-header="tableHeaderProps">
                <table-head v-bind="tableHeaderProps.rows">
                    <template v-slot:header-row="tableRowProps">
                        <table-row v-bind="tableRowProps">
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-6="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-7="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-head>
            </template>

            <template v-slot:table-body="tableBodyProps">
                <table-body v-bind="tableBodyProps.rows">
                    <template v-slot:body-row="tableRowProps">
                        <table-row v-bind="tableRowProps">
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell" :clamp="1">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-6="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-body>
            </template>

            <template v-slot:empty>
                <empty-message v-bind="surveyTableData.emptyMessage"/>
            </template>

            <template v-slot:footer v-if="surveyTableData.bodyRows.value.length">
                <p v-if="surveyTableData.pagination.pages.value.length">{{surveyTableData.pagination.pages.value.slice(-1)[0]}}ページ中 {{surveyTableData.pagination.currentPage.value}}ページ</p>
                <pagination :current-page="surveyTableData.pagination.currentPage.value" :pages="surveyTableData.pagination.pages.value">
                    <template v-slot:item="pagitaionItemProps">
                        <pagination-item
                            :is-current="pagitaionItemProps.item.isCurrent"
                            :disabled="pagitaionItemProps.item.disabled"
                            @clicked="$router.push({query: {...Object.assign({}, $route.query), ...{page: pagitaionItemProps.item.page}}})"
                        >
                            <template v-if="pagitaionItemProps.item.icon" v-slot:icon><icon :name="pagitaionItemProps.item.icon" /></template>
                            {{pagitaionItemProps.item.label}}
                        </pagination-item>
                    </template>
                </pagination>
            </template>
        </tables>
    </main>

    <modal
        v-bind="loadingModal"
        :id="loadingModal.id"
        :is-expanded="loadingModal.isExpanded.value"
        @close="$emit('closeModal', alertModal, $refs[`menuTrigger-${alertModal.currentId.value}`].$el)"
    >
        <template v-slot:header="modalHeaderProps">
            <modal-header :title="loadingModal.title" v-bind="modalHeaderProps.header"></modal-header>
        </template>

        <div class="t-main__spinner">
            <icon name="BigSpinner" />
        </div>
        <p class="t-main__loading-text" :id="loadingModal.ariaDescribedby">{{loadingModal.description}}</p>
        <p class="t-main__loading-text-small" :id="loadingModal.ariaDescribedby">{{loadingModal.caution}}</p>
    </modal>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Tables, {
    TableHead, TableBody, TableRow, TableCell
} from '@/components/01_Atoms/Tables';
import Modal, {ModalHeader} from '@/components/01_Atoms/ModalDialogs';
import {Tab, TabList} from '@/components/01_Atoms/Tabs';
import Pagination, {PaginationItem} from '@/components/01_Atoms/Paginations';
import {EmptyMessage} from '@/components/01_Atoms/Messages';
import Forms, {FormFooter, Field} from '@/components/01_Atoms/Forms';
import {TextField} from '@/components/01_Atoms/TextFields';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Tab, TabList, ButtonGroup, StandardButton, Icon, Tables, TableHead, TableBody, TableRow, TableCell, Modal, ModalHeader, Pagination, PaginationItem, EmptyMessage, Forms, FormFooter, Field, TextField
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        tabs: {
            type: Object,
            default: () => ({})
        },
        surveyTableData: {
            type: Object,
            default: () => ({})
        },
        actionMenu: {
            type: Object,
            default: () => ({})
        },
        alertModal: {
            type: Object,
            default: () => ({})
        },
        loadingModal: {
            type: Object,
            default: () => ({})
        },
        pagination: {
            type: Object,
            default: () => ({})
        },
        models: {
            type: Object,
            default: () => ({})
        },
        forms: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 0  24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__heading {
            display: flex;
            justify-content: space-between;
            padding: 48px 0 20px 0;

            > :last-child {
                flex: 0 0 auto;
            }
        }

        .t-main__utils {
            padding-bottom: 48px;
            margin: auto -24px;
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
        }

        .t-main__form {
            display: flex;

            form {
                display: flex;

                .a-form-footer {
                    button {
                        margin: 22.5px 0 0 50px;
                    }
                }
            }

            &-input {
                display: flex;
                gap: 20px;
            }
        }

        .t-main__spinner {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;

            > svg {
                animation: .86s linear 0s infinite normal none running animation-loading-spinner;
            }
        }

        .t-main__loading-text {
            text-align: center;

            &-small {
                font-size: 12px;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}
</style>
