import {createRouter, createWebHistory} from 'vue-router';
import VuebodyClass from 'vue-body-class';
import * as Admin from '@/components/05_Pages/Admin';
import * as Client from '@/components/05_Pages/Client';
import * as NotFound from '@/components/05_Pages/NotFound';

// 管理者画面のルーティング
const adminRoutes = [
    {
        path: '/admin/login',
        component: Admin.Login,
        meta: {
            bodyClass: 'admin-login'
        },
        children: [
            {
                path: '',
                name: 'AdminLogin',
                component: Admin.LoginForm
            }
        ]
    }, {
        path: '/admin/client-accounts',
        component: Admin.Default,
        meta: {
            bodyClass: 'admin'
        },
        children: [
            {
                path: '',
                name: 'AdminClientAccount',
                redirect: {name: 'AdminClientAccountApproved'}
            }, {
                path: 'approve',
                name: 'AdminClientAccountApproved',
                component: Admin.ClientAccountIndex
            }, {
                path: 'ban',
                name: 'AdminClientAccountBan',
                component: Admin.ClientAccountIndex
            }, {
                path: ':clientId',
                name: 'AdminClientAccountShow',
                component: Admin.ClientAccountShow
            }, {
                path: 'new',
                name: 'AdminClientAccountNew',
                component: Admin.ClientAccountNew
            }, {
                path: ':clientId/edit',
                name: 'AdminClientAccountEdit',
                component: Admin.ClientAccountEdit
            }
        ]
    }, {
        path: '/admin/requests',
        component: Admin.Default,
        meta: {
            bodyClass: 'admin'
        },
        children: [
            {
                path: '',
                name: 'AdminRequest',
                redirect: {name: 'AdminRequestPending'}
            }, {
                path: 'pending',
                name: 'AdminRequestPending',
                component: Admin.RequestIndex
            }, {
                path: 'approve',
                name: 'AdminRequestApproved',
                component: Admin.RequestIndex
            }, {
                path: ':requestId',
                name: 'AdminRequestShow',
                component: Admin.RequestShow
            }
        ]
    }, {
        path: '/admin/surveys',
        component: Admin.Default,
        meta: {
            bodyClass: 'admin',
            code: 'survey'
        },
        children: [
            {
                path: '',
                name: 'AdminClientSurveys',
                redirect: {name: 'AdminClientSurveysActive'}
            }, {
                path: 'active',
                name: 'AdminClientSurveysActive',
                component: Admin.AdminSurveysIndex
            }, {
                path: 'pending',
                name: 'AdminClientSurveysPending',
                component: Admin.AdminSurveysIndex
            }, {
                path: 'draft',
                name: 'AdminClientSurveysDraft',
                component: Admin.AdminSurveysIndex
            }, {
                path: 'waiting',
                name: 'AdminClientSurveysWaiting',
                component: Admin.AdminSurveysIndex
            }, {
                path: 'finished',
                name: 'AdminClientSurveysFinished',
                component: Admin.AdminSurveysIndex
            }, {
                path: ':surveyId/detail',
                name: 'AdminClientSurveysDetail',
                component: Admin.AdminSurveysDetail
            }
        ]
    }
];

// 利用者画面のルーティング
const clientRoutes = [
    {
        path: '/',
        name: 'Home',
        redirect: {name: 'ClientLogin'}
    }, {
        path: '/client',
        component: Client.Loggedin,
        name: 'ClientHome',
        meta: {
            bodyClass: 'client'
        }
    }, {
        path: '/client/login',
        component: Client.Login,
        meta: {
            bodyClass: 'client-login'
        },
        children: [
            {
                path: '',
                name: 'ClientLogin',
                component: Client.LoginForm
            }, {
                path: 'reissue-password',
                name: 'ClientReissuePassword',
                component: Client.ReissuePassword
            }
        ]
    }, {
        path: '/client/login/reset-password',
        component: Client.Default,
        meta: {
            bodyClass: 'client-request'
        },
        children: [
            {
                path: '',
                name: 'ClientResetPassword',
                component: Client.ResetPassword
            }
        ]
    }, {
        path: '/client/request',
        component: Client.Default,
        meta: {
            bodyClass: 'client-request'
        },
        children: [
            {
                path: '',
                name: 'ClientAgreement',
                component: Client.Request
            }, {
                path: 'entry',
                alias: ['confirm', 'completed'],
                beforeEnter(_, __, next) {
                    next({name: 'ClientAgreement'});
                }
            }
        ]
    }, {
        path: '/client/surveys',
        component: Client.Loggedin,
        meta: {
            bodyClass: 'client',
            code: 'survey'
        },
        children: [
            {
                path: '',
                name: 'ClientSurveys',
                redirect: {name: 'ClientSurveysActive'}
            }, {
                path: 'active',
                name: 'ClientSurveysActive',
                component: Client.SurveyIndex
            }, {
                path: 'pending',
                name: 'ClientSurveysPending',
                component: Client.SurveyIndex
            }, {
                path: 'draft',
                name: 'ClientSurveysDraft',
                component: Client.SurveyIndex
            }, {
                path: 'finished',
                name: 'ClientSurveysFinished',
                component: Client.SurveyIndex
            }, {
                path: 'new',
                name: 'ClientSurveysNew',
                component: Client.SurveyNew
            }, {
                path: ':surveyId/edit',
                name: 'ClientSurveysEdit',
                component: Client.SurveyEdit
            }, {
                path: ':surveyId/edit/question',
                name: 'ClientSurveysQuestion',
                component: Client.SurveyQuestion
            }, {
                path: ':surveyId/duplicate',
                name: 'ClientSurveysDuplicate',
                component: Client.SurveyDuplicate
            }, {
                path: ':surveyId/detail',
                name: 'ClientSurveysDetail',
                component: Client.SurveyDetail
            }
        ]
    }, {
        path: '/client/data_boxs',
        component: Client.Loggedin,
        meta: {
            bodyClass: 'client',
            code: 'data_box'
        },
        children: [
            {
                path: '',
                name: 'ClientDataBoxes',
                component: Client.DataboxIndex
            }
        ]
    }, {
        path: '/client/account-setting',
        component: Client.Setting,
        meta: {
            bodyClass: 'client'
        },
        children: [
            {
                path: '',
                name: 'ClientAccountSetting',
                component: Client.AccountSetting
            }, {
                path: 'password',
                name: 'ClientChangePassword',
                component: Client.ChangePassword
            }
        ]
    }, {
        path: '/client/terms',
        component: Client.Default,
        meta: {
            bodyClass: 'client-request'
        },
        children: [
            {
                path: '',
                name: 'ClientTerms',
                component: Client.Terms
            }
        ]
    }
];

// エラーページ（404 Page Not Found）
const notFounndRoutes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound.NotFound404
    }
];

const routes = [].concat(adminRoutes, clientRoutes, notFounndRoutes);
const vuebodyClass = new VuebodyClass(routes);
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, _, next) => vuebodyClass.guard(to, next));

export default router;
