<template>
    <div class="network-graph">
      <div class="title">{{ title }}</div>
      <div ref="graph" class="graph-container"></div>
    </div>
  </template>

<script>
import * as d3 from 'd3';

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        }
    },
    mounted() {
        if (this.$refs.graph) {
            this.createNetworkGraph();
        } else {
            console.error('graph reference is not found');
        }
    },
    methods: {
        createNetworkGraph() {
            const {nodes, links} = this.data;
            const width = 1200;
            const height = 800;

            const svg = d3.select(this.$refs.graph)
                .append('svg')
                .attr('width', width)
                .attr('height', height)
                .attr('viewBox', `0 0 ${width} ${height}`)
                .attr('preserveAspectRatio', 'xMidYMid meet');

            const link = svg.selectAll('.link')
                .data(links)
                .enter().append('line')
                .attr('class', 'link')
                .attr('stroke', '#999')
                .attr('stroke-width', (d) => Math.sqrt(d.value));

            const colorScale = d3.scaleLinear()
                .domain([0, 1, 2, 3, 4])
                .range(['#a6cee3', '#80b1d3', '#4d92c2', '#1f73b2', '#005f98']);

            const node = svg.selectAll('.node')
                .data(nodes)
                .enter().append('circle')
                .attr('class', 'node')
                .attr('r', (d) => this.getNodeRadius(d.frequency_level || 0))
                .attr('fill', (d) => colorScale(d.frequency_level || 0))
                .call(d3.drag()
                    .on('start', this.dragStart)
                    .on('drag', this.dragged)
                    .on('end', this.dragEnd));

            const labels = svg.selectAll('.label')
                .data(nodes)
                .enter().append('text')
                .attr('class', 'label')
                .attr('x', (d) => {
                    const offsetX = this.getOffsetByFrequencyLevel(d.frequency_level || 0, 'x');
                    return d.x !== undefined ? d.x + offsetX : offsetX;
                })
                .attr('y', (d) => {
                    const offsetY = this.getOffsetByFrequencyLevel(d.frequency_level || 0, 'y');
                    return d.y !== undefined ? d.y + offsetY : offsetY;
                })
                .text((d) => d.id);


            this.simulation = d3.forceSimulation(nodes)
                .force('link', d3.forceLink(links).id((d) => d.id).distance(130)) // リンクの長さを指定（長めに設定）
                .force('charge', d3.forceManyBody().strength(-115)) // 反発力
                .force('center', d3.forceCenter(width / 2, height / 2))
                .force('collide', d3.forceCollide(70)) // ノード間の最小距離
                .force('x', d3.forceX().strength(0.3)) // X方向の力
                .force('y', d3.forceY().strength(0.6)); // Y方向の力

            this.simulation.on('tick', () => {
                if (this.simulation.alpha() <= 0) return;

                link
                    .attr('x1', (d) => d.source.x)
                    .attr('y1', (d) => d.source.y)
                    .attr('x2', (d) => d.target.x)
                    .attr('y2', (d) => d.target.y);

                node
                    .attr('cx', (d) => d.x)
                    .attr('cy', (d) => d.y);

                labels
                    .attr('x', (d) => {
                        const offsetX = this.getOffsetByFrequencyLevel(d.frequency_level || 0, 'x');
                        return d.x !== undefined ? d.x + offsetX : offsetX;
                    })
                    .attr('y', (d) => {
                        const offsetY = this.getOffsetByFrequencyLevel(d.frequency_level || 0, 'y');
                        return d.y !== undefined ? d.y + offsetY : offsetY;
                    });
            });
        },
        getOffsetByFrequencyLevel(frequencyLevel, axis) {
            // ラベルの表示位置をノードの大きさによって調整
            const offsetMap = {
                x: [8, 14, 20, 26, 32],
                y: [10, 10, 10, 10, 10]
            };
            return offsetMap[axis][frequencyLevel] || 0;
        },
        getNodeRadius(frequencyLevel) {
            // frequencyLevel に基づいてノードの大きさを5段階で設定
            const sizeMap = [6, 12, 18, 24, 30];
            return sizeMap[frequencyLevel] || 5;
        },
        dragStart(event, d) {
            if (!event.active) {
                this.simulation.alphaTarget(0.3).restart();
            }
            d.fx = d.x;
            d.fy = d.y;
        },
        dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        },
        dragEnd(event, d) {
            if (!event.active) {
                this.simulation.alphaTarget(0);
            }
            d.fx = null;
            d.fy = null;
        }
    }
};
</script>

<style scoped>
.network-graph {
    width: 100%;
    padding: 10px;
    background-color: white;
    text-align: center;
 }

.title {
    font-size: 22px;
    font-weight: bold;
    color: black;
    text-align: center;
}

.graph-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.node {
    cursor: pointer;
}

.link {
    stroke: #999;
    stroke-width: 1.5px;
}

.label {
    font-size: 12px;
    pointer-events: none;
}
</style>
