<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__utils">
            <breadcrumbs :breadcrumbs="breadcrumbs">
                <template v-slot:item="breadcrumbsItemProps">
                    <breadcrumbs-item v-bind="breadcrumbsItemProps.breadcrumbs">
                        <template v-if="breadcrumbsItemProps.breadcrumbs.beforeIcon" v-slot:before><icon :name="breadcrumbsItemProps.breadcrumbs.beforeIcon" /></template>
                        {{breadcrumbsItemProps.breadcrumbs.label}}
                        <template v-if="breadcrumbsItemProps.breadcrumbs.afterIcon" v-slot:after><icon :name="breadcrumbsItemProps.breadcrumbs.afterIcon" /></template>
                    </breadcrumbs-item>
                </template>
            </breadcrumbs>
        </div>

        <div v-if="models.isLoading" class="t-main__spinner"><icon name="BigSpinner" /></div>
        <div class="t-main__graph" v-if="!models.isLoading">
            <div class="t-main__heading">
                <h1 class="t-main__title">アンケート詳細</h1>
            </div>
            <div class="t-main__definition-list" v-if="models.infomation.length > 0">
                <definition-list :items="models.infomation">
                    <template v-slot:item-0="itemProps">
                        <definition-list-term>{{itemProps.term}}</definition-list-term>
                        <definition-list-description>{{itemProps.description}}</definition-list-description>
                    </template>
                    <template v-slot:item-1="itemProps">
                        <definition-list-term>{{itemProps.term}}</definition-list-term>
                        <definition-list-description>{{itemProps.description}}</definition-list-description>
                    </template>
                    <template v-slot:item-2="itemProps">
                        <definition-list-term>{{itemProps.term}}</definition-list-term>
                        <definition-list-description>{{itemProps.description}}</definition-list-description>
                    </template>
                    <template v-slot:item-3="itemProps">
                        <definition-list-term>{{itemProps.term}}</definition-list-term>
                        <definition-list-description>{{itemProps.description}}</definition-list-description>
                    </template>
                    <template v-slot:item-4="itemProps">
                        <definition-list-term>{{itemProps.term}}</definition-list-term>
                        <definition-list-description>{{itemProps.description}}</definition-list-description>
                    </template>
                    <template v-slot:item-5="itemProps">
                        <definition-list-term>{{itemProps.term}}</definition-list-term>
                        <definition-list-description>{{itemProps.description}}</definition-list-description>
                    </template>
                    <template v-slot:item-6="itemProps">
                        <definition-list-term>{{itemProps.term}}</definition-list-term>
                        <definition-list-description>{{itemProps.description}}</definition-list-description>
                    </template>
                </definition-list>
            </div>
            <div class="t-main__no-content" v-if="models.eras.length == 0">
                アンケート回答が0件のため表示するデータがありません。
            </div>
            <div class="t-main__circle-graph">
                <div v-for="question in models.eras" :key="question.questionNumber">
                    <circle-graph
                        :title="question.question"
                        :colors="['#DCEDFE', '#B9D8FE', '#96C1FE', '#7BADFD', '#508CFC', '#3A6CD8', '#D3D3D3']"
                        :data="question.responses"
                    />
                </div>
            </div>
            <div class="t-main__circle-graph">
                <div v-for="question in models.genders" :key="question.questionNumber">
                    <circle-graph
                        :title="question.question"
                        :colors="['#7BADFD', '#FF9C7D', '#D3D3D3']"
                        :data="question.responses"
                    />
                </div>
            </div>
            <div class="t-main__graph-item" v-if="models.sense_codes.length > 0">
                <bar-graph title="価値観" valueLabel='回答者数' :unansweredCount=models.sense_code_unanswered_count :data=models.sense_codes></bar-graph>
            </div>
            <div class="t-main__graph-item" v-if="models.prefectures.length > 0">
                <bar-graph title="都道府県" valueLabel='回答者数' :unansweredCount=models.prefecture_unanswered_count :data=models.prefectures></bar-graph>
            </div>
            <div class="t-main__graph-item" v-if="models.selection.length > 0">
                <div v-for="question in models.selection" :key="question.question">
                    <bar-graph :title=question.question valueLabel='回答者数' :data=question.responses></bar-graph>
                </div>
            </div>
            <div class="t-main__network-graph">
                <NetWorkGraph
                    v-for="(question, index) in models.network"
                    :key="index"
                    :title="question.question"
                    :data="question.responses"
                />
            </div>
        </div>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {
    BarGraph, CircleGraph, NetWorkGraph
} from '@/components/01_Atoms/Graphs';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import Breadcrumbs, {BreadcrumbsItem} from '@/components/01_Atoms/Breadcrumbs';
import Icon from '@/components/01_Atoms/Icons';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Breadcrumbs, BreadcrumbsItem, BarGraph, CircleGraph, NetWorkGraph, Icon, DefinitionList, DefinitionListTerm, DefinitionListDescription
    },
    props: {
        breadcrumbs: {
            type: Array,
            default: () => []
        },
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        models: {
            type: Object
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 20px 24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;
    user-select: none;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__heading {
            text-align: left;
            padding: 28px 0 20px 0;
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
        }

        .t-main__graph {
            width: 100%;
            margin: 0 auto;
        }

        .t-main__graph-item {
            width: 90%;
            background-color: #fff;
            padding: 3rem 0;
            margin: 0 0 12px;
        }

        .t-main__circle-graph {
            width: 90%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 0 12px;
        }

        .t-main__spinner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            > svg {
                animation: .86s linear 0s infinite normal none running animation-loading-spinner;
            }
        }

        .t-main__network-graph {
            width: 90%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 0 12px;
        }
    }

    .t-main__definition-list {
        background-color: white;
        width: 90%;
        padding: 12px 36px 20px 36px;
        margin-bottom: 20px;
    }

    .t-main__no-content {
        font-weight: bold;
        font-size: 2.4rem;
        padding: 10px 0;
    }
}
</style>
