<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__content">
            <div class="t-main__utils">
                <breadcrumbs :breadcrumbs="breadcrumbs">
                    <template v-slot:item="breadcrumbsItemProps">
                        <breadcrumbs-item v-bind="breadcrumbsItemProps.breadcrumbs">
                            <template v-if="breadcrumbsItemProps.breadcrumbs.beforeIcon" v-slot:before><icon :name="breadcrumbsItemProps.breadcrumbs.beforeIcon" /></template>
                            {{breadcrumbsItemProps.breadcrumbs.label}}
                            <template v-if="breadcrumbsItemProps.breadcrumbs.afterIcon" v-slot:after><icon :name="breadcrumbsItemProps.breadcrumbs.afterIcon" /></template>
                        </breadcrumbs-item>
                    </template>
                </breadcrumbs>
            </div>

            <div class="t-main__heading">
                <h1 class="t-main__title">基本情報設定</h1>
            </div>

            <cards>
                <template v-slot:header>
                    <form-header title="アンケート情報" />
                </template>

                <banner v-if="formErrors.system.value.length" appearance="danger">
                    <template v-slot:icon><icon name="Error" /></template>
                    <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
                </banner>

                <forms id="survey-form" @submit.prevent="$emit('createSurvey', $event, models)">
                    <definition-list :items="forms.survey">
                        <template v-slot:item-0="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <file-input-field
                                    v-bind="itemProps.description"
                                    :is-error="formErrors.client_resources[itemProps.description.name] !== undefined"
                                    :aria-errormessage="`${itemProps.description.id}-invalid`"
                                    :aria-required="itemProps.term.isRequire"
                                    :model-value="models.client_resources[itemProps.description.name]"
                                    @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                >
                                    <template v-slot:icon><icon name="Upload" /></template>
                                </file-input-field>

                                <form-description :id="itemProps.description.ariaDescribedby">
                                    <p v-for="text in itemProps.description.description" :key="text">{{text}}</p>
                                </form-description>

                                <form-message v-if="formErrors.client_resources[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client_resources[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                                <template v-for="file in models.client_resources[itemProps.description.name]" :key="file">
                                    <component
                                        :is="file.type.startsWith('image') ? 'ImageFilePreview' : 'VideoFilePreview'"
                                        :filename="file.name"
                                        :size="file.size"
                                        :source="file.url"
                                    >
                                        <field v-bind="forms.client_resources.title" :label="`${file.type.startsWith('image') ? '画像' : '動画'}のタイトル`">
                                            <template v-slot:field="fieldProps">
                                                <text-field
                                                    v-bind="fieldProps.field"
                                                    :is-error="formErrors.client_resources[fieldProps.field.name] !== undefined"
                                                    :aria-errormessage="`${fieldProps.field.id}-invalid`"
                                                    :aria-required="itemProps.term.isRequire"
                                                    :model-value="models.client_resources[fieldProps.field.name]"
                                                    @update:model-value="$emit(`update:${fieldProps.field.name}`, $event)"
                                                />
                                                <form-message v-if="formErrors.client_resources[fieldProps.field.name]" :id="`${fieldProps.field.id}-invalid`" appearance="danger" :message="formErrors.client_resources[fieldProps.field.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                                            </template>
                                        </field>

                                        <field v-if="!file.type.startsWith('image')" v-bind="forms.client_resources.thumbnail">
                                            <template v-slot:field="fieldProps">
                                                <radio-button-group
                                                    direction="row"
                                                    :items="fieldProps.field.items.value"
                                                >
                                                    <template v-slot:radio="slotProps">
                                                        <image-radio-button
                                                            v-bind="slotProps.item"
                                                            :is-error="formErrors.client_resources[slotProps.item.name] !== undefined"
                                                            :aria-errormessage="`${slotProps.item.id}-invalid`"
                                                            :aria-required="itemProps.term.isRequire"
                                                            :model-value="models.thumbnail[slotProps.item.name]"
                                                            @update:model-value="$emit(`update:${slotProps.item.name}`, $event)"
                                                            @action="
                                                                $emit('setModelValue', models.thumbnail, 'custom_thumbnail', []) &
                                                                $emit('setModelValue', models.thumbnail, 'thumbnail', '0')
                                                            "
                                                        >
                                                            {{slotProps.item.label}}
                                                            <template v-slot:action v-if="slotProps.index === 1"><icon name="Failed" /><span class="u-altText">画像を削除</span></template>
                                                        </image-radio-button>
                                                    </template>

                                                    <template v-if="fieldProps.field.items.value.length === 1" v-slot:last-item>
                                                        <file-input-field
                                                            v-bind="forms.client_resources.custom_thumbnail"
                                                            appearance="image"
                                                            :is-error="formErrors.client_resources[forms.client_resources.custom_thumbnail.name] !== undefined"
                                                            :aria-errormessage="`${forms.client_resources.custom_thumbnail.id}-invalid`"
                                                            :aria-required="itemProps.term.isRequire"
                                                            :model-value="models.thumbnail[forms.client_resources.custom_thumbnail.name]"
                                                            @update:model-value="$emit(`update:${forms.client_resources.custom_thumbnail.name}`, $event)"
                                                        >
                                                            <template v-slot:icon><icon name="Upload" /></template>
                                                        </file-input-field>
                                                    </template>
                                                </radio-button-group>

                                                <form-message v-if="formErrors.client_resources[fieldProps.field.name]" :id="`${fieldProps.field.id}-invalid`" appearance="danger" :message="formErrors.client_resources[fieldProps.field.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                                            </template>
                                        </field>

                                        <template v-slot:footer>
                                            <action-button
                                                appearance="destructive"
                                                @click="
                                                    $emit('setModelValue', models.client_resources, itemProps.description.name, []) &
                                                    $emit('setModelValue', models.client_resources, forms.client_resources.title.name, '')
                                                "
                                            >
                                                <template v-slot:before><icon name="Delete" /></template>
                                                ファイルを削除
                                            </action-button>
                                        </template>
                                    </component>
                                </template>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-1="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <text-field
                                    v-bind="itemProps.description"
                                    :is-error="formErrors.survey[itemProps.description.name] !== undefined"
                                    :aria-errormessage="`${itemProps.description.id}-invalid`"
                                    :aria-required="itemProps.term.isRequire"
                                    :model-value="models.survey[itemProps.description.name]"
                                    @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                />
                                <form-message v-if="formErrors.survey[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-2="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <editor
                                    v-bind="itemProps.description"
                                    :is-error="formErrors.survey[itemProps.description.name] !== undefined"
                                    :aria-errormessage="`${itemProps.description.id}-invalid`"
                                    :aria-required="itemProps.term.isRequire"
                                    :model-value="models.survey[itemProps.description.name]"
                                    @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                />
                                <form-message v-if="formErrors.survey[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-3="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <pickers
                                    v-bind="itemProps.description"
                                    :is-error="formErrors.survey[itemProps.description.name] !== undefined"
                                    :is-expanded="itemProps.description.isExpanded.value"
                                    placement="auto-left"
                                    :value="models.survey[itemProps.description.name]"
                                    @open="$emit('openPicker', itemProps.description)"
                                >
                                    <template v-slot:icon><icon name="Calendar" /></template>
                                    <template v-slot:picker="pickerProps">
                                        <date-picker
                                            v-bind="pickerProps.picker"
                                            :model-value="models.survey[itemProps.description.name]"
                                            :range="itemProps.description.range"
                                            @open="$emit('openPicker', itemProps.description)"
                                            @close="$emit('closePicker', itemProps.description)"
                                            @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                        />
                                    </template>
                                    <template v-slot:action>
                                        <button
                                            type="button"
                                            :disabled="models.survey[itemProps.description.name] === null"
                                            @click="$emit('setModelValue', models.survey, itemProps.description.name, null)"
                                        >
                                            <icon name="Close" />
                                            <span class="u-altText">入力内容をリセット</span>
                                        </button>
                                    </template>
                                </pickers>
                                <form-message v-if="formErrors.survey[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-4="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <pickers
                                    v-bind="itemProps.description"
                                    :is-error="formErrors.survey[itemProps.description.name] !== undefined"
                                    :is-expanded="itemProps.description.isExpanded.value"
                                    placement="auto-left"
                                    :value="models.survey[itemProps.description.name]"
                                    @open="$emit('openPicker', itemProps.description)"
                                >
                                    <template v-slot:icon><icon name="Calendar" /></template>
                                    <template v-slot:picker="pickerProps">
                                        <date-picker
                                            v-bind="pickerProps.picker"
                                            :model-value="models.survey[itemProps.description.name]"
                                            :range="calendarRange"
                                            @open="$emit('openPicker', itemProps.description)"
                                            @close="$emit('closePicker', itemProps.description)"
                                            @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                        />
                                    </template>
                                    <template v-slot:action>
                                        <button
                                            type="button"
                                            :disabled="models.survey[itemProps.description.name] === null"
                                            @click="$emit('setModelValue', models.survey, itemProps.description.name, null)"
                                        >
                                            <icon name="Close" />
                                            <span class="u-altText">入力内容をリセット</span>
                                        </button>
                                    </template>
                                </pickers>
                                <form-message v-if="formErrors.survey[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-5="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <text-field
                                    v-bind="itemProps.description"
                                    class="u-width-220"
                                    :is-error="formErrors.survey[itemProps.description.name] !== undefined"
                                    :aria-errormessage="`${itemProps.description.id}-invalid`"
                                    :aria-required="itemProps.term.isRequire"
                                    :model-value="models.survey[itemProps.description.name]"
                                    @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                />
                                <form-message v-if="formErrors.survey[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-6="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <checkbox-group
                                    v-bind="itemProps.description"
                                    :child-model="models.survey[itemProps.description.children]"
                                >
                                    <template v-slot:checkbox="slotProps">
                                        <checkbox
                                            v-bind="slotProps.item"
                                            :name="itemProps.description.root"
                                            :is-error="formErrors.survey[itemProps.description.children] !== undefined"
                                            :indeterminate="slotProps.indeterminate"
                                            :children="slotProps.item.children || []"
                                            :model-value="models.rules[itemProps.description.root]"
                                            :child-value="models.survey[itemProps.description.children]"
                                            @update:model-value="$emit(`update:${itemProps.description.root}`, $event)"
                                            @update:child-value="$emit(`update:${itemProps.description.children}`, $event)"
                                        >{{slotProps.item.label}}</checkbox>
                                        <checkbox-group v-if="slotProps.hasChild" :items="slotProps.item.children" direction="row">
                                            <template v-slot:checkbox="slotProps">
                                                <checkbox
                                                    v-bind="slotProps.item"
                                                    :name="itemProps.description.children"
                                                    :is-error="formErrors.survey[itemProps.description.children] !== undefined"
                                                    :model-value="models.survey[itemProps.description.children]"
                                                    @update:model-value="$emit(`update:${itemProps.description.children}`, $event)"
                                                >{{slotProps.item.label}}</checkbox>
                                            </template>
                                        </checkbox-group>
                                    </template>
                                </checkbox-group>
                                <form-description :id="itemProps.description.ariaDescribedby">{{itemProps.description.description}}</form-description>
                                <form-message v-if="formErrors.survey[itemProps.description.children]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-7="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <range-input
                                    v-bind="itemProps.description"
                                    :is-error="formErrors.survey[itemProps.description.name] !== undefined"
                                    :aria-describedby="`${itemProps.description.id}-information`"
                                    :aria-errormessage="`${itemProps.description.id}-invalid`"
                                    :min-value="models.survey[itemProps.description.name][itemProps.description.modelNames.min]"
                                    :max-value="models.survey[itemProps.description.name][itemProps.description.modelNames.max]"
                                    @update:min-value="$emit(`update:${itemProps.description.modelNames.min}`, $event)"
                                    @update:max-value="$emit(`update:${itemProps.description.modelNames.max}`, $event)"
                                />
                                <form-message
                                    :id="`${itemProps.description.id}-information`"
                                    appearance="information"
                                    :message="rangeStatusMessage"
                                ><template v-slot:icon><icon name="Information" /></template></form-message>
                                <form-message v-if="formErrors.survey[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-8="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <checkbox-select
                                    v-bind="itemProps.description"
                                    :is-error="formErrors.survey[itemProps.description.children] !== undefined"
                                    :is-expanded="itemProps.description.isExpanded.value"
                                    :aria-describedby="`${itemProps.description.id}-information`"
                                    :root-value="models.rules[itemProps.description.root]"
                                    :model-value="models.survey[itemProps.description.children]"
                                    @open="$emit('openPicker', itemProps.description)"
                                    @close="$emit('closePicker', itemProps.description)"
                                    @update:root-value="$emit(`update:${itemProps.description.root}`, $event)"
                                    @update:model-value="$emit(`update:${itemProps.description.children}`, $event)"
                                >
                                    <template v-slot:option="optionProps">
                                        <select-option v-bind="optionProps.option" />
                                    </template>

                                    <template v-slot:action>
                                        <button
                                            type="button"
                                            :disabled="models.survey[itemProps.description.children].length === 0"
                                            @click="
                                                $emit('setModelValue', models.survey, itemProps.description.children, []) &
                                                $emit('setModelValue', models.rules, itemProps.description.root, [])
                                            "
                                        >
                                            <icon name="Failed" />
                                            <span class="u-altText">選択内容をリセット</span>
                                        </button>
                                    </template>
                                </checkbox-select>
                                <form-message v-if="formErrors.survey[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <template v-slot:item-9="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <tag-input-field
                                    v-bind="itemProps.description"
                                    :is-error="formErrors.survey[itemProps.description.name] !== undefined"
                                    :aria-errormessage="`${itemProps.description.id}-invalid`"
                                    :aria-required="itemProps.term.isRequire"
                                    :disabled="models.survey.zip_rule_prefecture.length === 47"
                                    :model-value="models.survey[itemProps.description.name]"
                                    @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                >
                                    <template v-slot:action>
                                        <button
                                            type="button"
                                            :disabled="models.survey[itemProps.description.name].length === 0"
                                            @click="$emit('setModelValue', models.survey, itemProps.description.name, [])"
                                        >
                                            <icon name="Failed" />
                                            <span class="u-altText">入力内容をリセット</span>
                                        </button>
                                    </template>
                                </tag-input-field>
                                <form-message
                                    :id="`${itemProps.description.id}-information`"
                                    appearance="information"
                                    :message="addressStatusMessage"
                                ><template v-slot:icon><icon name="Information" /></template></form-message>
                                <form-message v-if="formErrors.survey[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template>
                        <!-- <template v-slot:item-10="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <checkbox-group
                                    v-bind="itemProps.description"
                                    :child-model="models.survey[itemProps.description.children]"
                                >
                                    <template v-slot:checkbox="slotProps">
                                        <checkbox
                                            v-bind="slotProps.item"
                                            :name="itemProps.description.root"
                                            :is-error="formErrors.survey[itemProps.description.children] !== undefined"
                                            :indeterminate="slotProps.indeterminate"
                                            :children="slotProps.item.children || []"
                                            :model-value="models.rules[itemProps.description.root]"
                                            :child-value="models.survey[itemProps.description.children]"
                                            @update:model-value="$emit(`update:${itemProps.description.root}`, $event)"
                                            @update:child-value="$emit(`update:${itemProps.description.children}`, $event)"
                                        >{{slotProps.item.label}}</checkbox>
                                        <checkbox-group v-if="slotProps.hasChild" :items="slotProps.item.children">
                                            <template v-slot:checkbox="slotProps">
                                                <checkbox
                                                    v-bind="slotProps.item"
                                                    :name="itemProps.description.children"
                                                    :is-error="formErrors.survey[itemProps.description.children] !== undefined"
                                                    :model-value="models.survey[itemProps.description.children]"
                                                    @update:model-value="$emit(`update:${itemProps.description.children}`, $event)"
                                                >{{slotProps.item.label}}</checkbox>
                                            </template>
                                        </checkbox-group>
                                    </template>
                                </checkbox-group>
                                <form-message
                                    appearance="information"
                                    :message="itemProps.description.information"
                                ><template v-slot:icon><icon name="Information" /></template></form-message>
                                <form-description id="sense-role-description">
                                    <p v-for="text in itemProps.description.description" :key="text" v-html="text" />
                                </form-description>
                                <form-message v-if="formErrors.survey[itemProps.description.children]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.survey[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                            </definition-list-description>
                        </template> -->
                        <template v-slot:item-11="itemProps">
                            <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                            <definition-list-description>
                                <Toggle
                                    v-bind="itemProps.description"
                                    :name="itemProps.description.name"
                                    :model-value="models.survey[itemProps.description.name]"
                                    @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                    >
                                </Toggle>
                                <form-message
                                    appearance="information"
                                    :message="itemProps.description.information"
                                ><template v-slot:icon><icon name="Information" /></template></form-message>
                                <template v-if="models.survey[itemProps.description.name]">
                                    <file-input-field
                                        v-bind="itemProps.description"
                                        :isLoading="models.id_file[`${itemProps.description.name}_file_loading`]"
                                        :model-value="models.id_file[`${itemProps.description.name}_file`]"
                                        @update:model-value="$emit(`update:${itemProps.description.name}_file`, $event)"
                                    >
                                    <template v-slot:icon><icon name="Upload" /></template>
                                    </file-input-field>
                                    <form-description :id="itemProps.description.ariaDescribedby">
                                        <p v-for="text in itemProps.description.description" :key="text">{{text}}</p>
                                    </form-description>
                                    <form-message v-if="formErrors.id_file[`${itemProps.description.name}_file`]" appearance="danger" :message="formErrors.id_file[`${itemProps.description.name}_file`]">
                                        <template v-slot:icon><icon name="Error" /></template></form-message>
                                    <csv-file-count-preview
                                        :count="models.id_file[`${itemProps.description.name}_count`]"
                                    ></csv-file-count-preview>
                                    <template v-for="file2 in models.id_file[`${itemProps.description.name}_file`]" :key="file2">
                                        <csv-file-preview
                                            :filename="file2.name"
                                            :size="file2.size"
                                        >
                                        </csv-file-preview>
                                    </template>
                                </template>
                            </definition-list-description>
                        </template>
                    </definition-list>

                    <div class="t-footer" :class="{'is-menu-expanded': isMenuExpanded}">
                        <div class="t-footer__inner">
                            <button-group layout="end">
                                <standard-button :is-loading="forms.isLoading.value" type="submit" appearance="primary">登録する</standard-button>
                            </button-group>
                        </div>
                    </div>
                </forms>
            </cards>
        </div>
        <div class="t-main__previews">
            <previews>
                <template v-slot:body>
                    <preview-survey
                        :title="models.survey.bonus_name"
                        :end-date="models.survey.end_date"
                        :capacity="Number.isNaN(Number(models.survey.cap)) ? 0 : Number(models.survey.cap)"
                        v-html="previewMarkdown"
                    />
                </template>
            </previews>
        </div>
    </main>

    <modal
        v-bind="confirmModal"
        :id="confirmModal.id"
        :is-expanded="confirmModal.isExpanded.value"
    >
        <template v-slot:header="modalHeaderProps">
            <modal-header :title="confirmModal.title" v-bind="modalHeaderProps.header" />
        </template>

        <div :id="confirmModal.ariaDescribedby">
            <p v-for="text in confirmModal.description" :key="text">{{text}}</p>
        </div>

        <template v-slot:footer>
            <modal-footer>
                <button-group layout="end">
                    <standard-button @click="$router.push({name: 'ClientSurveys'})">一覧に戻る</standard-button>
                    <standard-button appearance="primary" @click="$emit('updateLocation', $event)">設問登録へ進む</standard-button>
                </button-group>
            </modal-footer>
        </template>
    </modal>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Banner from '@/components/01_Atoms/Banners';
import Breadcrumbs, {BreadcrumbsItem} from '@/components/01_Atoms/Breadcrumbs';
import Cards from '@/components/01_Atoms/Cards';
import Icon from '@/components/01_Atoms/Icons';
import ButtonGroup, {StandardButton, ActionButton} from '@/components/01_Atoms/Buttons';
import Forms, {
    FormHeader, FormLabel, FormMessage, FormDescription, Field
} from '@/components/01_Atoms/Forms';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';
import {TextField} from '@/components/01_Atoms/TextFields';
import TagInputField from '@/components/01_Atoms/TagInputFields';
import Editor from '@/components/01_Atoms/Editors';
import CheckboxGroup, {Checkbox} from '@/components/01_Atoms/Checkboxes';
import RadioButtonGroup, {ImageRadioButton} from '@/components/01_Atoms/RadioButtons';
import RangeInput from '@/components/01_Atoms/RangeInputs';
import Pickers, {DatePicker} from '@/components/01_Atoms/Pickers';
import {CheckboxSelect, SelectOption} from '@/components/01_Atoms/Selects';
import FileInputField, {
    ImageFilePreview, VideoFilePreview, CsvFilePreview, CsvFileCountPreview
} from '@/components/01_Atoms/FileInputFields';
import Previews, {PreviewSurvey} from '@/components/01_Atoms/Previews';
import Toggle from '@/components/01_Atoms/Toggles';

export default defineComponent({
    inheritAttrs: false,
    emits: [
        'createSurvey',
        'openPicker',
        'closePicker',
        'setModelValue',
        'updateLocation',
        'update:file',
        'update:custom_thumbnail',
        'update:thumbnail',
        'update:title',
        'update:bonus_name',
        'update:description',
        'update:start_date',
        'update:end_date',
        'update:cap',
        'update:gender_rule',
        'update:gender_rule_root',
        'update:min_age',
        'update:max_age',
        'update:zip_rule_prefecture',
        'update:zip_rule_prefecture_root',
        'update:zip_rule_code',
        'update:sense_rule_root',
        'update:use_id_filter',
        'update:use_id_filter_file',
        'update:use_id_filter_count',
        'use_id_filter_file_loading'
    ],
    components: {
        Banner,
        Breadcrumbs,
        BreadcrumbsItem,
        Cards,
        Icon,
        ButtonGroup,
        StandardButton,
        ActionButton,
        Forms,
        FormHeader,
        FormLabel,
        Field,
        FormMessage,
        FormDescription,
        DefinitionList,
        DefinitionListTerm,
        DefinitionListDescription,
        Modal,
        ModalHeader,
        ModalFooter,
        TextField,
        TagInputField,
        Editor,
        Pickers,
        DatePicker,
        CheckboxGroup,
        Checkbox,
        CheckboxSelect,
        RadioButtonGroup,
        ImageRadioButton,
        RangeInput,
        SelectOption,
        FileInputField,
        ImageFilePreview,
        VideoFilePreview,
        CsvFileCountPreview,
        CsvFilePreview,
        Previews,
        PreviewSurvey,
        Toggle
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        breadcrumbs: {
            type: Array,
            default: () => []
        },
        forms: {
            type: Object,
            default: () => ({})
        },
        formErrors: {
            type: Object,
            default: () => ({})
        },
        models: {
            type: Object,
            default: () => ({})
        },
        confirmModal: {
            type: Object,
            default: () => ({})
        },
        calendarRange: {
            type: Object,
            default: () => ({})
        },
        rangeStatusMessage: {
            type: String,
            default: ''
        },
        addressStatusMessage: {
            type: String,
            default: ''
        },
        previewMarkdown: {
            type: String,
            default: ''
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    display: flex;
    padding: 0  24px 100px 98px;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__content {
            flex-basis: 100%;
            margin-right: 24px;
        }

        .t-main__previews {
            position: sticky;
            top: 0;
            flex: 0 0 378px;
            margin: -72px -24px -36px 0;
            padding-top: 72px;
            height: calc(100vh - 76px);
            overflow: auto;
            background: var.$color-gray-10;
        }

        .t-main__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 0;

            > :last-child {
                flex: 0 0 auto;
            }
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
        }

        .t-main__utils {
            padding: 24px 0;
        }
    }
}

.t-footer {
    z-index: 3;
    position: fixed;
    right: 0;
    left: 73px;
    bottom: 0;
    background: var.$color-utils-background;
    transition: left .3s ease 0s;
    border-top: solid 1px #E6E7E6;

    &.is-menu-expanded {
        left: 210px;
    }

    @at-root {
        .t-footer__inner {
            padding: 16px 24px;
        }
    }
}
</style>
