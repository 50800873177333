<template>
  <survey-detail
      :is-menu-expanded="isMenuExpanded"
      :models = "models"
      :breadcrumbs = "breadcrumbs"
  />
</template>

<script>
// import composition-api.
import {
    defineComponent, reactive, onMounted
} from 'vue';
import axios from 'axios';
import store from '@/store';
import {SurveyDetail} from '@/components/04_Templates/Client';
import {useRoute} from 'vue-router';

export default defineComponent({
    inheritAttrs: false,
    components: {SurveyDetail},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const $route = useRoute();

        /**
         * breadcrumbs - パンくずリスト
         * @type Array<Object>
         */
        const breadcrumbs = [
            {
                label: 'アンケート管理',
                to: {name: 'ClientSurveys'},
                beforeIcon: 'Questionary'
            }, {
                label: 'アンケート詳細',
                to: {name: 'ClientSurveysDetail'}
            }
        ];

        const {surveyId} = $route.params;
        const models = reactive({
            isLoading: false,
            infomation: [],
            genders: [],
            eras: [],
            sense_codes: [],
            sense_code_unanswered_count: 0,
            prefectures: [],
            prefecture_unanswered_count: 0,
            network: [],
            selection: []
        });

        const fetchData = async (bonusId) => {
            models.isLoading = true;
            try {
                const response = await axios.get(`/api/v2/client_account/bonuses/${bonusId}/show_detail`, {
                    headers: store.state.auth,
                    'Content-Type': 'application/json'
                });
                models.infomation = response.data.summary.infomation;
                models.eras = response.data.summary.eras;
                models.genders = response.data.summary.genders;
                models.sense_codes = response.data.summary.sense_codes;
                models.sense_code_unanswered_count = response.data.summary.sense_code_unanswered_count;
                models.prefectures = response.data.summary.prefectures;
                models.prefecture_unanswered_count = response.data.summary.prefecture_unanswered_count;
                models.selection = response.data.selection;
                models.network = response.data.network;
            } catch (error) {
                console.error('データの取得に失敗しました:', error);
            }
            models.isLoading = false;
        };

        onMounted(() => {
            fetchData(surveyId);
        });

        return {
            models, breadcrumbs
        };
    }
});
</script>

<style lang="scss" scoped></style>
