<template>
  <div ref="root" class="a-graph-circle">
      <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import {
    defineComponent, ref, onMounted, watch
} from 'vue';
import {
    Chart, PieController, ArcElement, Tooltip, Legend, Title
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ArcElement, PieController, Tooltip, Legend, Title, ChartDataLabels);

export default defineComponent({
    inheritAttrs: false,
    props: {
        data: {
            type: Array
        },
        title: {
            type: String
        },
        colors: {
            type: Array
        }
    },
    setup(props) {
        const chartCanvas = ref(null);
        let chartInstance = null;

        const renderChart = () => {
            if (chartInstance) {
                chartInstance.destroy();
            }

            chartInstance = new Chart(chartCanvas.value, {
                type: 'pie',
                data: {
                    labels: props.data.map((item) => item.label),
                    datasets: [{
                        data: props.data.map((item) => item.value),
                        backgroundColor: props.colors,
                        borderColor: '#fff',
                        borderWidth: 2
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: 100,
                            right: 40,
                            top: 10,
                            bottom: 40
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: props.title,
                            color: '#000',
                            font: {
                                size: 24,
                                weight: 'bold'
                            },
                            position: 'top',
                            align: 'center'
                        },
                        legend: {
                            display: true,
                            position: 'right',
                            labels: {
                                color: '#000',
                                font: {
                                    size: 18,
                                    weight: 'bold'
                                },
                                padding: 50
                            }
                        },
                        tooltip: {
                            enabled: true
                        },
                        datalabels: {
                            color: '#000',
                            font: {
                                size: 16,
                                weight: 'bold'
                            },
                            formatter: (value, context) => {
                                if (value === 0) {
                                // 0の場合はラベルを表示すると、
                                // 横の要素と重なって見えづらくなる場合があるので、
                                // 表示しないようにする
                                    return '';
                                }
                                const {data} = context.dataset;
                                const total = data.reduce((acc, val) => acc + val, 0);
                                const percentage = ((value / total) * 100).toFixed(1);
                                const label = context.chart.data.labels[context.dataIndex];

                                return `${label}\n${value} (${percentage}%)`;
                            },
                            anchor: (context) => {
                                const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                                const percentage = ((context.dataset.data[context.dataIndex] / total) * 100).toFixed(1);
                                return percentage < 10 ? 'end' : 'center';
                            },
                            align: (context) => {
                                const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                                const percentage = ((context.dataset.data[context.dataIndex] / total) * 100).toFixed(1);
                                return percentage < 10 ? 'end' : 'center';
                            },
                            offset: (context) => {
                                const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                                const percentage = ((context.dataset.data[context.dataIndex] / total) * 100).toFixed(1);
                                const labelOffset = percentage < 10 ? 10 : 0;
                                if (context.dataIndex % 2 === 0) {
                                    return labelOffset + 5;
                                }
                                return labelOffset - 5;
                            }
                        }
                    }
                }
            });
        };

        onMounted(() => {
            renderChart();
        });

        watch(
            () => [props.data, props.labels, props.colors],
            () => {
                renderChart();
            },
            {deep: true}
        );

        return {
            chartCanvas
        };
    }
});
</script>

<style lang="scss" scoped>
.a-graph-circle {
    width: 100%;
    height: 700px;
    max-width: 800px;
    margin: 20px;
    display: flex;
    justify-content: center;
}
</style>
