<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__heading">
            <h1 class="t-main__title">アンケート管理</h1>
        </div>

        <div class="t-main__form">
            <forms @submit.prevent="$emit('search-table-data', $event)">
                <div class="t-main__form-input">
                    <field v-bind="forms.clientName">
                        <template v-slot:field="fieldProps">
                            <text-field
                                v-bind="fieldProps.field"
                                :aria-required="fieldProps.field.isRequire"
                                :model-value="models.finishedSearch.clientName"
                                @update:model-value="$emit('update:finishedClientName', $event)"
                            >
                                <template v-slot:action>
                                    <button type="button" :disabled="models.finishedSearch.clientName === ''" @click="$emit('set-finished-model-value', 'clientName', '')"><icon name="Close" /></button>
                                </template>
                            </text-field>
                        </template>
                    </field>

                    <div class="t-main__form-input-wrap">
                        <field v-bind="forms.clientId">
                            <template v-slot:field="fieldProps">
                                <text-field
                                    v-bind="fieldProps.field"
                                    :aria-required="fieldProps.field.isRequire"
                                    :model-value="models.finishedSearch.clientId"
                                    @update:model-value="$emit('update:finishedClientId', $event)"
                                >
                                    <template v-slot:action>
                                        <button type="button" :disabled="models.finishedSearch.clientId === ''" @click="$emit('set-finished-model-value', 'clientId', '')"><icon name="Close" /></button>
                                    </template>
                                </text-field>
                            </template>
                        </field>
                    </div>

                    <div class="t-main__form-input-wrap">
                        <field v-bind="forms.surveyId">
                            <template v-slot:field="fieldProps">
                                <text-field
                                    v-bind="fieldProps.field"
                                    :aria-required="fieldProps.field.isRequire"
                                    :model-value="models.finishedSearch.surveyId"
                                    @update:model-value="$emit('update:finishedSurveyId', $event)"
                                >
                                    <template v-slot:action>
                                        <button type="button" :disabled="models.finishedSearch.surveyId === ''" @click="$emit('set-finished-model-value', 'surveyId', '')"><icon name="Close" /></button>
                                    </template>
                                </text-field>
                            </template>
                        </field>
                    </div>

                    <div class="t-main__form-input-wrap">
                        <field v-bind="forms.requestedStartDate">
                            <template v-slot:field="{field}">
                                <pickers
                                    v-bind="field"
                                    :is-expanded="field.isExpanded.value"
                                    placement="auto-left"
                                    :value="models.finishedSearch.requestedStartDate"
                                    @open="$emit('openPicker', field)"
                                >
                                    <template v-slot:icon><icon name="Calendar" /></template>
                                    <template v-slot:picker="pickerProps">
                                        <date-picker
                                            v-bind="pickerProps.picker"
                                            :model-value="models.finishedSearch.requestedStartDate"
                                            :range="field.range"
                                            @open="$emit('openPicker', field)"
                                            @close="$emit('closePicker', field)"
                                            @update:model-value="$emit(`update:finishedRequestedStartDate`, $event)"
                                        />
                                    </template>
                                    <template v-slot:action>
                                        <button
                                            type="button"
                                            :disabled="models.finishedSearch.requestedStartDate === null"
                                            @click="$emit('set-finished-model-value', field.name, null)"
                                        >
                                            <icon name="Close" />
                                            <span class="u-altText">入力内容をリセット</span>
                                        </button>
                                    </template>
                                </pickers>
                            </template>
                        </field>
                    </div>

                    <div class="t-main__form-input-wrap">
                        <field v-bind="forms.requestedEndDate">
                            <template v-slot:field="{field}">
                                <pickers
                                    v-bind="field"
                                    :is-expanded="field.isExpanded.value"
                                    placement="auto-left"
                                    :value="models.finishedSearch.requestedEndDate"
                                    @open="$emit('openPicker', field)"
                                >
                                    <template v-slot:icon><icon name="Calendar" /></template>
                                    <template v-slot:picker="pickerProps">
                                        <date-picker
                                            v-bind="pickerProps.picker"
                                            :model-value="models.finishedSearch.requestedEndDate"
                                            :range="field.range"
                                            @open="$emit('openPicker', field)"
                                            @close="$emit('closePicker', field)"
                                            @update:model-value="$emit(`update:finishedRequestedEndDate`, $event)"
                                        />
                                    </template>
                                    <template v-slot:action>
                                        <button
                                            type="button"
                                            :disabled="models.finishedSearch.requestedEndDate === null"
                                            @click="$emit('set-finished-model-value', field.name, null)"
                                        >
                                            <icon name="Close" />
                                            <span class="u-altText">入力内容をリセット</span>
                                        </button>
                                    </template>
                                </pickers>
                            </template>
                        </field>
                    </div>
                </div>

                <template v-slot:footer>
                    <form-footer>
                        <button-group>
                            <standard-button appearance="primary" type="submit" spacing="fill" :is-loading="forms.isLoading.value"><b>検索</b></standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>
        </div>

        <div class="t-main__utils">
            <tab-list v-bind="tabs" :current-index="tabs.currentIndex.value">
                <template v-slot:tab="slotProps">
                    <tab v-bind="slotProps.item" :is-external-link="false">{{slotProps.item.label}}</tab>
                </template>
            </tab-list>
        </div>

        <tables v-bind="surveyTableData" :is-loading="surveyTableData.isLoading.value" :body-rows="surveyTableData.bodyRows.value">
            <template v-slot:table-header="tableHeaderProps">
                <table-head v-bind="tableHeaderProps.rows">
                    <template v-slot:header-row="tableRowProps">
                        <table-row v-bind="tableRowProps">
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-6="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-7="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-8="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-head>
            </template>

            <template v-slot:table-body="tableBodyProps">
                <table-body v-bind="tableBodyProps.rows">
                    <template v-slot:body-row="tableRowProps">
                        <table-row v-bind="tableRowProps">
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell" :clamp="1">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-6="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-7="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-8="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">
                                    <menus :id="`action-menu-${tableCellProps.cell.content}`" v-bind="actionMenu" placement="auto-right" :is-expanded="actionMenu.isExpanded.value && actionMenu.currentId.value === tableCellProps.cell.content">
                                        <template v-slot:menu-trigger="menuTriggerProps">
                                            <icon-button
                                                v-bind="menuTriggerProps.trigger"
                                                title="メニュー"
                                                :ref="`menuTrigger-${tableCellProps.cell.content}`"
                                                @click.stop="$emit('openMenu', actionMenu, tableCellProps.cell.content)"
                                                @click="$emit('get-survey-name', tableRowProps)"
                                            ><icon name="More" /></icon-button>
                                        </template>

                                        <template v-slot:menu-list="menuListProps">
                                            <menu-list v-bind="menuListProps.menus" @close="$emit('closeMenu', actionMenu)">
                                                <template v-slot:menu-item="menuItemProps">
                                                    <menu-item
                                                        v-bind="menuItemProps.item"
                                                        :disabled="menuItemProps.item.key === 'download' && !tableCellProps.cell.downloadEnabled"
                                                        @click.stop="
                                                            $emit(menuItemProps.item.emitEvent, confirmModal, tableCellProps.cell.content) &
                                                            $emit('closeMenu', actionMenu)
                                                        "
                                                    >
                                                        <template v-if="menuItemProps.item.beforeIcon" v-slot:before><icon :name="menuItemProps.item.beforeIcon" /></template>
                                                        {{menuItemProps.item.label}}
                                                        <template v-if="menuItemProps.item.afterIcon" v-slot:after><icon :name="menuItemProps.item.afterIcon" /></template>
                                                    </menu-item>
                                                </template>
                                            </menu-list>
                                        </template>
                                    </menus>
                                </table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-body>
            </template>

            <template v-slot:empty>
                <empty-message v-bind="surveyTableData.emptyMessage"/>
            </template>

            <template v-slot:footer v-if="surveyTableData.bodyRows.value.length">
                <p v-if="surveyTableData.pagination.pages.value.length">{{surveyTableData.pagination.pages.value.slice(-1)[0]}}ページ中 {{surveyTableData.pagination.currentPage.value}}ページ</p>
                <pagination :current-page="surveyTableData.pagination.currentPage.value" :pages="surveyTableData.pagination.pages.value">
                    <template v-slot:item="pagitaionItemProps">
                        <pagination-item
                            :is-current="pagitaionItemProps.item.isCurrent"
                            :disabled="pagitaionItemProps.item.disabled"
                            @clicked="$router.push({query: {...Object.assign({}, $route.query), ...{page: pagitaionItemProps.item.page}}})"
                        >
                            <template v-if="pagitaionItemProps.item.icon" v-slot:icon><icon :name="pagitaionItemProps.item.icon" /></template>
                            {{pagitaionItemProps.item.label}}
                        </pagination-item>
                    </template>
                </pagination>
            </template>
        </tables>
    </main>

    <modal
        v-bind="confirmModal"
        :id="confirmModal.id"
        :is-expanded="confirmModal.isExpanded.value"
        @close="$emit('closeModal', confirmModal, $refs[`menuTrigger-${confirmModal.currentId.value}`].$el)"
    >
        <template v-slot:header="modalHeaderProps">
            <modal-header :title="confirmModal.title" v-bind="modalHeaderProps.header">
                <template v-slot:icon><icon name="Warning" /></template>
            </modal-header>
        </template>

        <p :id="confirmModal.ariaDescribedby">{{confirmModal.description}}</p>

        <template v-slot:footer>
            <modal-footer>
                <button-group layout="end">
                    <standard-button :disabled="confirmModal.isLoading.value" @click="$emit('closeModal', confirmModal, $refs[`menuTrigger-${confirmModal.currentId.value}`].$el)">キャンセル</standard-button>
                    <standard-button appearance="primary" :is-loading="confirmModal.isLoading.value" @click="$emit('updateRequestStatus', $event)">請求待ちに戻る</standard-button>
                </button-group>
            </modal-footer>
        </template>
    </modal>

    <modal
        v-bind="loadingModal"
        :id="loadingModal.id"
        :is-expanded="loadingModal.isExpanded.value"
        @close="$emit('closeModal', alertModal, $refs[`menuTrigger-${alertModal.currentId.value}`].$el)"
    >
        <template v-slot:header="modalHeaderProps">
            <modal-header :title="loadingModal.title" v-bind="modalHeaderProps.header"></modal-header>
        </template>

        <div class="t-main__spinner">
            <icon name="BigSpinner" />
        </div>
        <p class="t-main__loading-text" :id="loadingModal.ariaDescribedby">{{loadingModal.description}}</p>
        <p class="t-main__loading-text-small" :id="loadingModal.ariaDescribedby">{{loadingModal.caution}}</p>
    </modal>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import ButtonGroup, {StandardButton, IconButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Tables, {
    TableHead, TableBody, TableRow, TableCell
} from '@/components/01_Atoms/Tables';
import Menus, {MenuItem, MenuList} from '@/components/01_Atoms/Menus';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';
import {Tab, TabList} from '@/components/01_Atoms/Tabs';
import Pagination, {PaginationItem} from '@/components/01_Atoms/Paginations';
import {EmptyMessage} from '@/components/01_Atoms/Messages';
import Forms, {FormFooter, Field} from '@/components/01_Atoms/Forms';
import {TextField} from '@/components/01_Atoms/TextFields';
import Pickers, {DatePicker} from '@/components/01_Atoms/Pickers';

export default defineComponent({
    inheritAttrs: false,
    components: {
        DatePicker, Pickers, Tab, TabList, ButtonGroup, StandardButton, IconButton, Icon, Tables, TableHead, TableBody, TableRow, TableCell, Menus, MenuItem, MenuList, Modal, ModalHeader, ModalFooter, Pagination, PaginationItem, EmptyMessage, Forms, FormFooter, Field, TextField
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        tabs: {
            type: Object,
            default: () => ({})
        },
        surveyTableData: {
            type: Object,
            default: () => ({})
        },
        actionMenu: {
            type: Object,
            default: () => ({})
        },
        confirmModal: {
            type: Object,
            default: () => ({})
        },
        loadingModal: {
            type: Object,
            default: () => ({})
        },
        pagination: {
            type: Object,
            default: () => ({})
        },
        models: {
            type: Object,
            default: () => ({})
        },
        forms: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 0  24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__heading {
            display: flex;
            justify-content: space-between;
            padding: 48px 0 20px 0;

            > :last-child {
                flex: 0 0 auto;
            }
        }

        .t-main__utils {
            padding-bottom: 48px;
            margin: auto -24px;
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
        }

        .t-main__form {
            display: flex;

            form {
                display: flex;

                .a-form-footer {
                    button {
                        margin: 22.5px 0 0 50px;
                    }
                }
            }

            &-input {
                display: flex;
                gap: 20px;
            }
        }

        .t-main__spinner {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;

            > svg {
                animation: .86s linear 0s infinite normal none running animation-loading-spinner;
            }
        }

        .t-main__loading-text {
            text-align: center;

            &-small {
                font-size: 12px;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}
</style>
